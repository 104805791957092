import elliScript from '@elliemae/em-ssf-guest';
import { EXPIRATION_DATE_OFFSET, createApiUtils } from '@tsp-ui/core';
import { isAfter } from 'date-fns';
import { JwtPayload, jwtDecode } from 'jwt-decode';


export const apiUtils = {
    ...createApiUtils({
        getRequestUrl: (url, _, config) => config?.apiUrl + url,
        getAuthToken: async (url) => `Bearer ${await getAuthToken(url)}`
    }),

    getTokenPayload: async () => decodeAuthToken(await getAuthToken(undefined)),

    getEncompassOrigin,

    createEncompassTransaction
};


const AUTH_URL = '/auth';

let authToken = '';
let authTokenPromise: Promise<AuthResponse> | null = null;

function fetchAuthToken(body: AuthRequestBody): Promise<AuthResponse> {
    return apiUtils.post(AUTH_URL, body);
}

export async function getAuthToken(url: string | undefined): Promise<string> {
    if (url === AUTH_URL) {
        return '';
    }

    if (authToken) {
        const { exp } = decodeAuthToken(authToken);
        const expiration = new Date(exp * 1000);

        return isAfter(new Date(Date.now() + EXPIRATION_DATE_OFFSET), expiration)
            ? await refreshAuthToken()
            : authToken;
    }

    return await refreshAuthToken();
}

async function getEncompassOrigin() {
    const transaction = await elliScript.getObject('transaction');
    let origin = await transaction.getOrigin();

    const { exp } = jwtDecode(origin.partnerAccessToken);
    const expiration = new Date(exp! * 1000);

    if (isAfter(new Date(Date.now() + EXPIRATION_DATE_OFFSET), expiration)) {
        console.log('[DPR] Partner access token expired. Refreshing origin');
        origin = await transaction.refreshOrigin();
    }

    return origin;
}

export enum EncompassTransactionType {
    GET_PROGRAMS = 'Get eligible programs',
    IMPORT_CONDITIONS = 'Import conditions'
}

export async function createEncompassTransaction(transactionType: EncompassTransactionType) {
    const transactionObject = await elliScript.getObject('transaction');

    return await transactionObject.create({
        request: {
            type: transactionType,
            options: {}
        }
    });
}

async function refreshAuthToken() {
    if (!authTokenPromise) {
        console.log('[DPR] Refreshing auth token');

        const origin = await getEncompassOrigin();

        console.log(`[DPR] auth origin id: ${origin.id}`);
        console.log(`[DPR] partnerAccessToken: ${origin.partnerAccessToken}`);

        authTokenPromise = fetchAuthToken({
            originId: origin.id,
            partnerAccessToken: origin.partnerAccessToken
        });
    }

    try {
        authToken = (await authTokenPromise).token;
    } catch (e) {
        console.error('An error occurred while refreshing the auth token: ', e);
        throw e;
    } finally {
        authTokenPromise = null;
    }

    console.log('[DPR] auth token obtained');

    return authToken;
}

function decodeAuthToken(token: string): AuthTokenPayload {
    const decodedToken = jwtDecode(token) as JwtPayload & {
        isAdmin: string;
        canImportConditions: string;
    };

    return {
        ...decodedToken,
        isAdmin: decodedToken.isAdmin.toLowerCase() === 'true',
        canImportConditions: decodedToken.canImportConditions.toLowerCase() === 'true'
    } as AuthTokenPayload;
}

interface AuthRequestBody {
    originId: string;
    partnerAccessToken: string;
}

interface AuthResponse {
    token: string;
}

export interface AuthTokenPayload {
    exp: number;
    isAdmin: boolean;
    canImportConditions: boolean;
    programDetailsView: ProgramDetailsView;
    personaNames: string[];
}

export enum ProgramDetailsView {
    LO = 'LO',
    UW = 'UW'
}

export const programDetailsViewDisplay = {
    [ProgramDetailsView.LO]: 'Loan Officer',
    [ProgramDetailsView.UW]: 'Underwriter'
};
